<template>
  <div class="config-container">
    <div class="config-header">
      <div :class="['header-tab-item', item.code == current ? 'header-item-selected' : '']" v-for="(item, idx) in menus" :key="idx"
        @click="clickMenu(item)">{{showLang(item.lang)}}</div>
    </div>
    <div class="config-content">
      <template v-for="(item, idx) in menus">
        <component v-if="item.code == current" :key="idx" v-bind:is="item.com" :args="item.args"></component>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import AnalyseAlarmTrend from './trend/Index'
import AnalyseStationAlarmTypes from './types/Powerbox'
import AnalyseLightAlarmTypes from './types/Light'
export default {
  name: 'Home',
  components: {
    // AuthMenuItem
  },
  props: {
    sys: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      current: 's1',
      menus: [
        { code: 's1', lang:'com.data.alarm.levels.analysis',name: '报警等级趋势分析', com: AnalyseAlarmTrend, args: {} },
        { code: 's2', lang:'com.powerbox.alarm.type.analysis',name: '配电柜报警类型排行', com: AnalyseStationAlarmTypes, args: {} },
        { code: 's3', lang:'com.light.alarm.type.analysis',name: '单灯报警类型排行', com: AnalyseLightAlarmTypes, args: {} },
      ],
    }
  },
  watch: {
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
  },
  methods: {
    clickMenu: function (item) {
      this.current = item.code;
    },
  }
}
</script>
<style scoped>
.config-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 5px;
}
.config-header {
  height: 34px;
  flex: none;
  display: flex;
  margin: 15px 0;
}

.header-tab-item {
  border-bottom: 0;
  cursor: pointer;
  padding: 0 10px;
  line-height: 34px;
  background-color: #eef4f9;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
}
.header-tab-item:first-child {
  border-radius: 6px 0px 0px 0px;
}
.header-tab-item:last-child {
  border-radius: 0px 6px 0px 0px;
}
.header-item-selected {
  background-color: #3880fc;
  color: #ffffff;
}
.config-content {
  height: 500px;
  flex: auto;
}
</style>