<template>
  <div class="organ-container">
    <div class="organ-header">
      <div class="header-tab-item">
        {{showLang('com.date.start')}}：
        <DatePicker type="date" v-model="filter.start" style="width: 115px;margin-right:10px"></DatePicker>
        {{showLang('com.date.end')}}：
        <DatePicker type="date" v-model="filter.end" style="width: 115px;margin-right:10px"></DatePicker>
        <Button v-if="funCodes('aalv')" type="primary" @click="getList"
          style="margin-right:10px">{{showLang('com.op.query')}}</Button>
        <Button v-if="funCodes('aale')" type="success" @click="exportData">{{showLang('com.op.export')}}</Button>
      </div>
    </div>
    <div class="organ-content">
      <Spin fix v-if="spin.show">{{spin.content}}</Spin>
      <div class="chart-area">
        <chartsBar :option="chartsLineData" id="chartsLineData" :key="timer"></chartsBar>
      </div>
      <div class="data-area" ref="table">
        <vxe-table ref="chkTable" border show-overflow show-header-overflow :data="tabData" :height="tabHeight"
          :row-config="{isHover: true}">
          <vxe-column type="seq" width="60" :title="showLang('com.export.cn.seq')"></vxe-column>
          <vxe-column field="time" :title="showLang('com.date')" header-align="center">
            <template #default="{ row }">
              {{new Date(row.date).format('yyyy-MM-dd')}}
            </template>
          </vxe-column>
          <vxe-column field="third" :title="showLang('com.data.alarm.level')" header-align="center">
            <template #default="{ row }">
              {{getAlarmLevel(row.level)}}
            </template>
          </vxe-column>
          <vxe-column field="count" :title="showLang('com.data.alarm.num')" header-align="center"></vxe-column>
        </vxe-table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'AnalyseAlarmTrend',
  components: {
  },
  props: {
  },
  data() {
    return {
      spin: {
        show: false,
        content: 'Loading...',
      },
      editModal: false,
      tabHeight: 500,
      tabData: [],
      filter: {
        start: '',
        end: '',
      },
      timer: 0,
      chartsLineData: {
        color: ['#5470C6', '#91CC75', '#FAC858'],
        title: "",
        x: [],
        unit: "次",
        series: [
          {
            name: "预报警",
            data: [],
          },
          {
            name: "普通报警",
            data: [],
          },
          {
            name: "严重报警",
            data: [],
          },
        ],
        setSeries: {
          smooth: true, //平滑折线图
          areaStyle: {}, //下阴影
        },
      },
      alarmLevels: { //报警等级名称
        10: '预报警',
        20: '普通报警',
        30: '严重报警',
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('auth', ['appType']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    stationIds: function () {
      return this.selectedDevices.map(p => p.id);
    },
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
    selectedNode() {
      this.getList();
    },
  },
  mounted: function () {
    let now = new Date();
    this.filter.end = now.format('yyyy-MM-dd');
    now.setMonth(now.getMonth() - 1);
    this.filter.start = now.format('yyyy-MM-dd');
    setTimeout(this.setTableHeight, 200);
    this.getList();
    this.chartsLineData.unit = this.showLang('com.unit.order');
    this.chartsLineData.series[0].name = this.showLang('alarm.level.pre');
    this.chartsLineData.series[1].name = this.showLang('alarm.level.common');
    this.chartsLineData.series[2].name = this.showLang('alarm.level.fatal');
    this.alarmLevels = {
      10: this.showLang('com.stat.alarm.pre'),// '预报警',
      20: this.showLang('com.stat.alarm.ordinary'),// '普通报警',
      30: this.showLang('com.stat.alarm.big'),// '严重报警',
    }
  },
  methods: {
    getAlarmLevel: function (level) {
      if (this.alarmLevels[level]) return this.alarmLevels[level];
      return this.showLang('com.not.set');
    },
    exportData() {
      this.$refs.chkTable.exportData({ type: 'csv' })
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight - 10;
    },
    getList: function () {
      let groupId = 0, stationId = 0;
      if(this.selectedNode.type == this.productCodes.station){
        stationId = this.selectedNode.id;
      }else{
        groupId = this.selectedNode.id;
      }
      let start = new Date(this.filter.start).format('yyyy-MM-dd');
      let end = new Date(this.filter.end).format('yyyy-MM-dd');
      this.spin.show = true;
      let args = {
        start: start,
        end: end,
        stationId: stationId,
        groupId: groupId,
      }
      // let urlType = 'analyse/alarm/trend';
      let urlType = 'station/alarm/AnalyseByLevel';
      // if (this.appType == 'light') {
      //   urlType = 'station/light/AlarmLevel';
      // }
      this.$axios.post(`//${this.domains.trans}/${urlType}`, args).then(res => {
        this.spin.show = false;
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'tabData', res.data);
          let x = [], y10 = [], y20 = [], y30 = [];
          for (let d of res.data) {
            let date = new Date(d.date).format('yyyy-MM-dd');
            if (x.indexOf(date) == -1) {
              x.push(date);
            }
          }
          for (let d of x) {
            // console.log('chk alarm', d)
            let l10s = res.data.filter(p => new Date(p.date).format('yyyy-MM-dd') == d && p.level == 10);
            y10.push(l10s.length == 0 ? 0 : l10s[0].count);
            let l20s = res.data.filter(p => new Date(p.date).format('yyyy-MM-dd') == d && p.level == 20);
            y20.push(l20s.length == 0 ? 0 : l20s[0].count);
            let l30s = res.data.filter(p => new Date(p.date).format('yyyy-MM-dd') == d && p.level == 30);
            y30.push(l30s.length == 0 ? 0 : l30s[0].count);
          }
          this.$set(this.chartsLineData, 'x', x);
          this.$set(this.chartsLineData.series[0], 'data', y10);
          this.$set(this.chartsLineData.series[1], 'data', y20);
          this.$set(this.chartsLineData.series[2], 'data', y30);
          this.timer++;
        }
      });
    },
  }
}
</script>
<style scoped>
.organ-container {
  /* border: solid 1px rgb(180, 109, 3); */
  height: 100%;
  display: flex;
  flex-direction: column;
}
.organ-header {
  height: 40px;
  margin: 3px;
  flex: none;
}
.chart-area {
  height: 300px;
  flex: none;
}
.data-area {
  height: 500px;
  flex: auto;
}
.organ-content {
  /* border: solid 1px red; */
  margin: 3px;
  height: 500px;
  flex: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}
.header-tab-item {
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 40px;
  /* justify-content: flex-end; */
  /* padding: 0 20px; */
}
</style>
