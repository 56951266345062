<template>
  <div class="asset-container">

    <div class="asset-content">
      <div class="asset-tree">
        <CommonTree />
      </div>
      <div class="analyse-content">
        <div class="asset-slider">
          <div :class="['asset-slider-item', item.code == current ? 'asset-item-selected' : '']" v-for="(item, idx) in menus" :key="idx" @click="clickMenu(item)">{{showLang(item.lang)}}</div>
        </div>
        <template v-for="(item, idx) in menus">
          <component v-if="item.code == current" :key="idx" v-bind:is="item.com" :args="item.args"></component>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import CommonTree from '../tree/Index'
import AnalyseStatusIndex from '@/components/analyse/status/Index'
import AnalyseEnergyIndex from '@/components/analyse/energy/Index'
import AnalyseAlarmIndex from '@/components/analyse/alarm/Index'
export default {
  name: 'Home',
  components: {
    CommonTree,
  },
  props: {
  },
  data() {
    return {
      deploy: false,
      current: 's3',
      menus: [
        { code: 's3', lang:'com.analysis.energy',name: '能耗分析', com: AnalyseEnergyIndex, args: {} },
        { code: 's2', lang:'com.analysis.dev.type',name: '设备状态分析', com: AnalyseStatusIndex, args: {} },
        { code: 's4', lang:'com.analysis.alarm',name: '报警分析', com: AnalyseAlarmIndex, args: {} },
        // { code: 's1', lang:'com.analysis.quality',name: '电能质量分析', com: AnalyseQualityIndex, args: {} },
      ],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('group', ['selectedNode', 'groups', 'treeData', 'selectedDevices']),
    ...mapState('common', ['weeks', 'productCodes']),
    stationIds: function () {
      return this.selectedDevices.map(p => p.id);
    },
  },
  watch: {
    // selectedNode(){
    //   console.log('node tree changed', this.selectedDevices, this.stationIds);
    // },
  },
  mounted: function () {
  },
  methods: {
    // initTree: function(){
    //   console.log('init tree', this.treeData);
    // },
    clickMenu: function (item) {
      this.current = item.code;
    },
  }
}
</script>
<style scoped>
.asset-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 3px;
}
.asset-slider {
  height: 40px;
  flex: none;
  margin: 0 5px;
  border-radius: 6px;
  /* padding: 3px; */
  display: flex;
}
.asset-slider-item {
  padding: 2px 15px 2px 0;
  cursor: pointer;
  background-color: #fff;
  color: #324252;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 48px;
}
.asset-item-selected {
  color: #021629;
  font-weight: bold;
}
.asset-item-selected::after {
  content: "";
  width: calc(100%);
  height: 1px;
  display: block;
  margin: -10px auto 0;
  border-bottom: 4px solid #3880fc;
}
.asset-content {
  height: 550px;
  flex: auto;
  display: flex;
  background-color:  #E3E9EF;
}
.asset-tree {
  border-radius: 6px;
  width: 300px;
  flex: none;
  margin-right: 5px;
}
.analyse-content {
  flex: auto;
  overflow: hidden;
  background-color: #fff;
  padding: 15px;
  margin: 15px;
}
</style>
